/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { computed, reactive } from '@nuxtjs/composition-api';
import axios from 'axios';
import { getListUrlRewritesByTargetPaths } from '~/serverMiddleware/getGeminiData';

const state = reactive({
  geminiData: null,
  loading: false,
  error: null,
});

const useGeminiApi = () => {
  const geminiData = computed(() => state.geminiData);
  const loading = computed(() => state.loading);
  const error = computed(() => state.error);

  const getGeminiData = async (entityGrn: string) => {
    state.loading = true;
    state.geminiData = null;
    state.error = null;
    try {
      if (!process.client) {
        state.geminiData = await getListUrlRewritesByTargetPaths(entityGrn);
      } else {
        const response = await axios.get(`/api/getgeminidata`, {
          params: {
            grn: entityGrn,
            time: Date.now(),
          },
        });
        state.geminiData = response.data;
      }
    } catch (err) {
      state.error = err;
    }
    state.loading = false;
  };

  return {
    geminiData,
    loading,
    error,
    getGeminiData,
  };
};

export default useGeminiApi;
